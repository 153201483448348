import React from 'react'

const Button = (props) => {
    return (
        <a href=' https://g.co/kgs/YbW6cW7
        ' className='bg-red-700 text-xl text-white font-semibold py-2 px-6 rounded-lg md:ml-6 hover:bg-red-500
        duration-500'>
            {props.children}
        </a>
  )
}

export default Button