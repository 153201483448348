import React, { useState } from 'react';
import Button from './Button';
import NavLogo from '../Assests/IMPERIOLOGOWHITE.png';
const Navbar = () => {
//   const Links = [
//     { name: "HOME", link: "/" },
//     { name: "ABOUT", link: "/" },
//     { name: "LOCATIONS", link: "/" },
//     { name: "SERVICE", link: "/" },
//     { name: "CONTACT", link: "/" },
  // ];
  const [open, setOpen] = useState(false);

  return (
    <div className='shadow-md w-full h-0 fixed top-0 left-0 z-10'>
      <div className='md:flex items-center justify-between bg-transparent py-4 px-10'>
        <div className='font-bold text-xl cursor-pointer flex items-center text-gray-800'>
          <div className=' mr-1 pt-2'>
            <img src={NavLogo} alt='logo' className='w-30 h-20' />
          </div>
        </div>
        <div onClick={() => setOpen(!open)} className='text-3xl absolute right-8 top-6 cursor-pointer md:hidden'>
          <ion-icon name={open ? 'close' : 'menu'}></ion-icon>
        </div>
        <ul className={`md:flex md:justify-end md:pb-0 pb-12 absolute md:static bg-transparent
        md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-500 ease-in 
        ${open ? 'top-20 opacity-100' : 'top-[-490px]'}
        md:opacity-100 opacity-0`}>
        
          {/* {Links.map((link) => (
            <li key={link.name} className='md:ml-8 text-xl md:my-10 my-10'>
              <a href={link.link} className='text-gray-800 hover:text-gray-400 duration-500'>{link.name}</a>
            </li>
          ))} */}
          <li className='md:ml-8 text-xl md:my-10 my-7'>
            <Button>Order Now</Button>
          </li>
        </ul>
      </div>
    </div>
  )
};

export default Navbar;
