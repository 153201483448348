import React from 'react'

const Button = (props) => {
    return (
        <a href='https://imperiorestaurant.dotpe.in/
        ' className='bg-red-900 text-white font-semibold py-2 px-6 rounded-xl md:ml-6 hover:bg-red-500
        duration-500'>
            {props.children}
        </a>
  )
}

export default Button