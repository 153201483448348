import './App.css';
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   Link
// } from "react-router-dom";
import Navbar from './components/Navbar';
import { DarkModeProvider } from './components/DarkModeContext';
import Home from './Sections/Home';
// import About from './Sections/About';
// import Locations from './Sections/Locations';
// import Footer from './components/Footer';
// import Contact from './Sections/Contact';


function App() {
  return (
    <>
      {/* <DarkModeProvider>
      <div className='bg-red-600 w-full h-screen'> */}
      <Navbar/>
      <Home/>
      {/* <About/> */}
      {/* <Locations/> */}
      {/* <Contact/> */}
      {/* <Footer/> */}
        
 {/* </div>  */}
      {/* </DarkModeProvider> */}

    </>
      );
}

export default App;
