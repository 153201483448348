// import React, { useEffect } from 'react'
// // import { useDarkMode } from '../components/DarkModeContext'
// import AOS from 'aos'
// import 'aos/dist/aos.css'
// import bgimage from '../Assets/IMPERIO lOGO.png'
// import logo from '../Assests/logo.png'
// import ReadButton from '../components/ReadButton'

// const Home = () => {

//     useEffect(() => {
//         AOS.init({
//             offset: 200,
//             duration: 800,
//             easing: 'ease-in-sine',
//             delay: 100,
//         });

//     }, [])
//     // const { darkMode, toggleDarkMode } = useDarkMode();
//     return (
//         <>
//             <section id='home' className='w-full bg-black flex justify-between items-center text-white px-10 py-20 bg-cover bg-center'
//                 style={{ backgroundImage: `url(${bgimage})` }}>
//             <div className='flex flex-col justify-center items-center lg:items-start gap-8 w-full 
//          lg:w-2/3 pl-10 pr-2 lg:pl-32 lg:pr-10'>
//             <div data-aos="slide-right" id='box-circle' className='bg-white p-12 rounded-full flex justify-center items-center flex-col gap-4 w-15 mt-10'>
//                 <img src='logo.png' alt='logo'/>

//             </div>
//                 <h1 data-aos="zoom-in-up" className='text-3xl font-semibold'>IMPERIO RESTAURANT</h1>
//                 <h2 data-aos="zoom-in" className='text-5xl lg:text-6xl font-bold text-center lg:text-start'>Enjoy your Meal,<br></br>Full of Flavor and Fun</h2>
//             <ReadButton data-aos="zoom-in-up">Read More</ReadButton>
//             </div>

//              </section>

//         </>
//     )
// }

// export default Home
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import React from 'react';
// import classNames from 'classnames';
// import LOGO from '../Assests/website3.jpg';
// import Button from "../components/Brookfield";
// import Button1 from "../components/KalyanNagar"
// import Button2 from "../components/HSR"
// import Button3 from "../components/Whitefield"
// import Button4 from "../components/Kadugodi"
// import './Home.css'

// const Home = () => {
//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         initialSlide: 1,
//         responsive: [
//           {
//             breakpoint: 1024,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               infinite: true,
//               dots: true
//             }
//           },
//           {
//             breakpoint: 600,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1,
//               initialSlide: 1
//             }
//           },
//           {
//             breakpoint: 480,
//             settings: {
//               slidesToShow: 1,
//               slidesToScroll: 1
//             }
//           }
//         ]
//       };

//     return (
//         <>
//             <section
//                 id="home"
//                 className="relative w-full min-h-screen flex flex-col justify-between text-white px-4 md:px-10 py-20 bg-cover bg-center"
//                 style={{ backgroundImage: `url(${LOGO})` }}
//             >
             
//                 <div className="w-full md:w-1/2 mt-10 flex flex-col">
//                     <div className="font-bold text-white text-3xl md:text-4xl flex justify-center md:justify-center">Our Locations</div>
//                     <div className="mt-10 md:mt-20">
//                         <Slider {...settings}>
//                             {data.map((d, index) => (
//                                 <div key={index} className="bg-inherit h-[450px] font-bold text-black rounded-xl">
//                                     <div className="h-56 rounded-t-xl bg-transparent flex justify-center items-center">
//                                         <img src={d.img} alt="" />
//                                     </div>
//                                     <div className="flex flex-col justify-center items-center gap-4 p-4">
//                                         <p className="text-lg md:text-xl font-semibold">{d.name}</p>
//                                         <p >{d.review}</p>
//                                         <p >{d.info}</p>
//                                     </div>
//                                 </div>
//                             ))}
//                         </Slider>
//                     </div>
//                 </div>

//             </section>
//         </>
//     );
// }


// const data=[
//     {
//         name:`Imperio Restaurant Kalyan Nagar`,
//         img:`logo.png`,
//         review: `For More Info, Click below`,
//         info: <Button1>Reach Us</Button1>,
//     },
//     {
        
//         name:`Imperio Restaurant HSR Layout`,
//         img:`logo.png`,
//         // review: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dicta suscipit voluptas consectetur impedit magni at voluptates ratione officia ducimus deleniti.`
//         review: `For More Info, Click below`,
//         info:<Button2>Reach Us</Button2>
//     },
//     {
//         name:`Imperio Restaurant Brookfield`,
//         img:`logo.png`,
//         review: `For More Info, Click below`,
//         info: <Button>Reach us</Button>,
//         // review: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dicta suscipit voluptas consectetur impedit magni at voluptates ratione officia ducimus deleniti.`
//     },
//     {
//         name:`Imperio Restaurant Whitefield`,
//         img:`logo.png`,
//         review: `For More Info, Click below`,
//         info: <Button3>Reach Us</Button3>
//         // review: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dicta suscipit voluptas consectetur impedit magni at voluptates ratione officia ducimus deleniti.`
//     },
//     {
//         name:`Imperio Restaurant Kadugodi`,
//         img:`logo.png`,
//         review: `For More Info, Click below`,
//         info: <Button4>Reach Us</Button4>
//         // review: `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Dicta suscipit voluptas consectetur impedit magni at voluptates ratione officia ducimus deleniti.`
//     },
// ]

// export default Home
// import React, { useState, useEffect } from 'react';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import './Home.css'; // Import your CSS file
// import Button from "../components/Brookfield";
// import Button1 from "../components/KalyanNagar";
// import Button2 from "../components/HSR";
// import Button3 from "../components/Whitefield";
// import Button4 from "../components/Kadugodi";

// const Home = () => {
//     const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

//     useEffect(() => {
//         const handleResize = () => {
//             setIsMobile(window.innerWidth <= 767);
//         };

//         window.addEventListener('resize', handleResize);

//         // Cleanup listener on component unmount
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const settings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 2,
//         slidesToScroll: 1,
//         initialSlide: 1,
//         responsive: [
//             {
//                 breakpoint: 1500,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 2,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 900,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 2,
//                     initialSlide: 2,
//                     infinite: true,
//                     dots: true
//                 }
//             },
//             {
//                 breakpoint: 500,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     initialSlide:1
//                 }
//             },
//             {
//                 breakpoint: 300,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                     initialSlide:1
//                 }
//             }
//         ]
//     };

//     const data = [
//         {
//             name: `Imperio Restaurant Kalyan Nagar`,
//             img: `logo.png`,
//             review: `For More Info, Click below`,
//             info: <Button1>Reach Us</Button1>,
//         },
//         {
//             name: `Imperio Restaurant HSR Layout`,
//             img: `logo.png`,
//             review: `For More Info, Click below`,
//             info: <Button2>Reach Us</Button2>
//         },
//         {
//             name: `Imperio Restaurant Brookfield`,
//             img: `logo.png`,
//             review: `For More Info, Click below`,
//             info: <Button>Reach us</Button>,
//         },
//         {
//             name: `Imperio Restaurant Whitefield`,
//             img: `logo.png`,
//             review: `For More Info, Click below`,
//             info: <Button3>Reach Us</Button3>
//         },
//         {
//             name: `Imperio Restaurant Kadugodi`,
//             img: `logo.png`,
//             review: `For More Info, Click below`,
//             info: <Button4>Reach Us</Button4>
//         },
//     ];

//     return (
//         <>
//             <section
//                 id="home"
//                 className={`relative w-full min-h-screen flex flex-col justify-between text-white px-4 md:px-10 py-20 bg-cover bg-center ${isMobile ? 'background-phone' : 'background-desktop'}`}
//             >
//                 <div className="w-full md:w-1/2 mt-10 flex flex-col">
//                     <div className="font-bold text-white text-3xl md:text-4xl flex justify-center md:justify-center">Our Locations</div>
//                     <div className="mt-10 md:mt-20">
//                         <Slider {...settings}>
//                             {data.map((d, index) => (
//                                 <div key={index} className="bg-inherit bg-red-950 h-[450px] font-bold text-white rounded-xl">
//                                     <div className="h-56 rounded-t-xl bg-transparent flex justify-center items-center">
//                                         <img src={d.img} alt="" />
//                                     </div>
//                                     <div className="flex flex-col justify-center items-center gap-4 p-5">
//                                         <p className="text-lg md:text-xl font-semibold">{d.name}</p>
//                                         <p>{d.review}</p>
//                                         <p>{d.info}</p>
//                                     </div>
//                                 </div>
//                             ))}
//                         </Slider>
//                     </div>
//                 </div>
//             </section>
//         </>
//     );
// }

// export default Home;
import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Home.css'; // Import your CSS file
import Button from "../components/Brookfield";
import Button1 from "../components/KalyanNagar";
import Button2 from "../components/HSR";
import Button3 from "../components/Whitefield";
import Button4 from "../components/Kadugodi";
import logo from '../Assests/logo.png';

const Home = () => {
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
        autoplay:true,
        autoplaySpeed:2000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            }
        ]
    };

    const data = [
        {
            name: `Imperio Restaurant Kalyan Nagar`,
            img: logo,
            review: `For More Info, Click below`,
            info: <Button1>Reach Us</Button1>,
        },
        {
            name: `Imperio Restaurant HSR Layout`,
            img: logo,
            review: `For More Info, Click below`,
            info: <Button2>Reach Us</Button2>
        },
        {
            name: `Imperio Restaurant Brookfield`,
            img: logo,
            review: `For More Info, Click below`,
            info: <Button>Reach us</Button>,
        },
        {
            name: `Imperio Restaurant Whitefield`,
            img: logo,
            review: `For More Info, Click below`,
            info: <Button3>Reach Us</Button3>
        },
        {
            name: `Imperio Restaurant Kadugodi`,
            img: logo,
            review: `For More Info, Click below`,
            info: <Button4>Reach Us</Button4>
        },
    ];

    return (
        <section
            id="home"
            className={`home-section ${isMobile ? 'background-phone' : 'background-desktop'}`}
        >
            <div className="content-wrapper">
                <div className="title">Our Locations</div>
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {data.map((d, index) => (
                            <div key={index} className="slider-item">
                                <div className="image-container">
                                    <img className="" src={d.img} alt="" />
                                </div>
                                <div className="info-container">
                                    <p className="item-name">{d.name}</p>
                                    <p className='item-info'>{d.review}</p>
                                    <p className='item-info1'>{d.info}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default Home;
